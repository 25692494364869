@font-face {
  font-family: lora;
  src: url(font/lora.ttf);
}

body {
  background-color: #eeeeee;
  font-family: lora, serif;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.App {
  background-color: #eeeeee;
  display:flex;
  flex-direction:column;
  min-height: 100vh;
}

.AppHeader {
  position: relative;
  background-image: url("images/background.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 170px;
  text-align: center;
  font-family: lora, serif;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 14px -3px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 2px 14px -3px rgba(0,0,0,0.2);
  box-shadow: 0 2px 14px -3px rgba(0,0,0,0.2);
}

h1 {
  color: white;
  font-size: 36px !important;
  margin: 0;
  font-weight: bold !important;
  letter-spacing: 3px;
}

h3 {
  color: white;
  font-size: 15px !important;
  margin: 0;
  font-weight: normal !important;
}

.TitleHolder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Main {
  margin-top: 2rem;
  padding: 1rem;
}

.Container {
  max-width: 900px;
  margin: 0 auto;
}

.Box {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  text-align: center;
}

.MainCounter {
  font-size: 52px;
  font-weight: bold;
  padding-bottom: 2rem;
  padding-top: 2rem;
  letter-spacing: 6px;
}

.PrayerTimesContainer {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.PrayerTimesBox {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  box-shadow: 0 2px 10px -3px rgba(0,0,0,0.2);
  text-align: center;
  margin-bottom: 15px;
}

.Spacer {
  width: 3rem;
  height: 100%;
}

.PrayerTimesTitle {
  margin-top: 15px;
  font-weight: bold;
}

.PrayerTimesTime {
  margin-top: 5px;
  margin-bottom: 15px;
}

.Footer {
  margin-top: auto;
  text-align: center;
  padding: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #2a2a2a;
}

.AppStore {
  margin-bottom: 1rem;
}

.StoreBadge {
  height: 40px;
  cursor: pointer;
}

.LocationSelectHolder {
  text-align: right;
  padding-bottom: 10px;
}

.LocationSelectButton {
  justify-content: right;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.LocationSelectButton:hover {
  color: #808080;
}

.LocationSelectHolder img {
  height: 20px;
  width: 20px;
}

.BottomLink {
  font-size: 12px;
}

.dropdown {
  width: 100% !important;
}

a:link {
  color: #2a2a2a;
  text-decoration: none;
}

a:visited {
  color: #2a2a2a;
  text-decoration: none;
}

a:hover {
  color: #545454 !important;
  text-decoration: none;
  border-bottom: 1px dotted #545454;
}

a:active {
  color: #2a2a2a;
  text-decoration: none;
}

@media only screen and (max-width: 850px) {
  h1 {
    font-size: 32px !important;
  }

  h3 {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 750px) {
  h1 {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 346px) {
  h1 {
    font-size: 20px !important;
  }

  h3 {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 600px) {
  .PrayerTimesContainer {
    flex-direction: column;
  }
}
